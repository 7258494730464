import { BrandBar } from "molecules";
import { Layout, Questionnaire } from 'organisms';
import { Metadata } from "atoms";
import React from "react";
import data from "data/melalogic-skin-conditions.json";

const IndexPage = () => (<div data-testid="index">
  <Metadata title="Melalogic" keywords={data.map( ({ label }) => label )} />
  <Layout center>
    <BrandBar layout="wide" />
    <Questionnaire />
  </Layout>
</div>)

export default IndexPage
